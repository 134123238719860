import React from "react"
import { Helmet } from "react-helmet"
import { onSuccessCashnowAtm } from "../../components/UI/PopUp"
import Cash from "../../images/cashNow/groshi.png"
import Header from "../../modules/watermelon/Header"

import "./index.scss"

const OkPage = () => {
  const redirectToMain = () => {
    window.location.href = "https://cash-plus.tascombank.ua"
    return false
  }

  React.useEffect(() => {
    onSuccessCashnowAtm(redirectToMain)
  }, [])

  return (
    <>
      <Header />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="cashnowatm--main cash-now-thanks">
        <div className="cashnowatm--main__wrapper">
          <div className="cashnowatm--main__image">
            <img src={Cash} style={{ maxWidth: "480px" }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default OkPage
